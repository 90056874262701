import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// Components
import Hero from '../components/hero'

class FAQPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedIndex: null,
    }
  }

  isExpanded(index) {
    return index === this.state.expandedIndex ? true : false
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Ekko App - FAQ</title>
        </Helmet>

        <Hero
          title="Tak for tilmelding! 👍 "
          subTitle={
            <>
              Vi har sendt dig en mail med yderligere oplysninger.
              <br />
              (Tjek evt. Dit spam filter)
            </>
          }
          primaryUseAnchorTag={true}
          primaryActionHref="/webinar-intern-kommunikation"
          primaryAction="Gå tilbage til webinaret"
        />
      </Layout>
    )
  }
}

export default FAQPage
